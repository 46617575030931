import { RoundedTitle } from "components/shared/panda/RoundedTitle"
import PortableTitle from "components/shared/PortableTitle"
import SmallForm from "components/shared/SmallForm"
import { parseBackground, useSizing } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import { BigBanner } from "schemas"
import { Center, Container, HStack, styled } from "styled-system/jsx"

type Props = BigBanner

function Columns({
  title,
  subtitle,
  body,
  bodyStyle,
  quote,
  quoteLogo,
  inputLabel,
  ctaLabel,
  formPage,

  sizingTop,
  sizingBottom,
  backgroundColor1,
  backgroundColor2,
  backgroundStyle,
}: Props) {
  return (
    <styled.div
      className="bigBanner"
      style={
        {
          background: parseBackground(
            backgroundColor1,
            backgroundColor2,
            backgroundStyle
          ),
          "--pt": useSizing(sizingTop),
          "--pb": useSizing(sizingBottom),
        } as React.CSSProperties
      }
      css={{
        pt: "var(--pt)",
        pb: "var(--pb)",
      }}>
      <Container>
        <styled.div
          css={{
            textAlign: "center",
            color: "white",
            p: "32px 14px 28px",
            bgImage:
              "url(/stats-bg.svg), linear-gradient(91deg, #0000CF 0%, #060086 100%)",
            backgroundPosition: "center 55%",
            rounded: "20px",

            lg: {
              p: "44px 44px 80px",
            },
          }}>
          {subtitle && (
            <Center>
              <RoundedTitle as="h5" color="white" responsive>
                {subtitle}
              </RoundedTitle>
            </Center>
          )}
          <PortableTitle
            as="div"
            altColor="#0CDCB7"
            css={{
              fontFamily: "heading",
              fontSize: { base: "14px", lg: "32px" },
              fontWeight: 700,
              lineHeight: "150%",
              mb: { base: 2, lg: 4 },
              letterSpacing: {
                base: "-0.266px",
                lg: "-0.608px",
              },
            }}>
            {title}
          </PortableTitle>
          {bodyStyle === "text" && (
            <styled.p
              mb={{ base: 6, lg: "56px" }}
              css={{
                fontSize: "22px",
                letterSpacing: "-0.418px",

                lgDown: {
                  fontSize: "12px",
                  fontWeight: 400,
                  letterSpacing: "-0.228px",
                },
              }}>
              {body}
            </styled.p>
          )}
          <Center mt={bodyStyle === "quote" ? 8 : 0}>
            <SmallForm
              inputProps={{
                placeholder: inputLabel,
              }}
              submitLabel={ctaLabel}
              buttonProps={{ variant: "outline", outlineColor: "primary" }}
              formPage={formPage?.slug}
            />
          </Center>
          {bodyStyle === "quote" && (
            <>
              <HStack alignItems="start" mt={10} gap={1}>
                <styled.img src="/open-quote.svg" />
                <styled.p
                  mb={"56px"}
                  css={{
                    color: "#DEDEDE",
                    fontFamily: "heading",
                    fontSize: "22px",
                    fontStyle: "italic",
                    fontWeight: "500",
                    lineHeight: "150%",
                    mb: 6,

                    lgDown: {
                      fontSize: "12px",
                      fontWeight: 400,
                      letterSpacing: "-0.228px",
                    },
                  }}>
                  {quote}
                </styled.p>
                <styled.img src="/close-quote.svg" />
              </HStack>
              <Center>
                <styled.img
                  src={urlForImage(quoteLogo).url()}
                  css={{ lgDown: { width: "80px" } }}
                />
              </Center>
            </>
          )}
        </styled.div>
      </Container>
    </styled.div>
  )
}

export default Columns
export type { Props as ColumnsProps }
