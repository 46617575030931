function Error() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none">
      <mask
        id="mask0_661_3967"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_661_3967)">
        <path
          d="M12.5 17C12.7833 17 13.0208 16.9042 13.2125 16.7125C13.4042 16.5208 13.5 16.2833 13.5 16C13.5 15.7167 13.4042 15.4792 13.2125 15.2875C13.0208 15.0958 12.7833 15 12.5 15C12.2167 15 11.9792 15.0958 11.7875 15.2875C11.5958 15.4792 11.5 15.7167 11.5 16C11.5 16.2833 11.5958 16.5208 11.7875 16.7125C11.9792 16.9042 12.2167 17 12.5 17ZM12.5 13C12.7833 13 13.0208 12.9042 13.2125 12.7125C13.4042 12.5208 13.5 12.2833 13.5 12V8C13.5 7.71667 13.4042 7.47917 13.2125 7.2875C13.0208 7.09583 12.7833 7 12.5 7C12.2167 7 11.9792 7.09583 11.7875 7.2875C11.5958 7.47917 11.5 7.71667 11.5 8V12C11.5 12.2833 11.5958 12.5208 11.7875 12.7125C11.9792 12.9042 12.2167 13 12.5 13ZM9.575 21C9.30833 21 9.05417 20.95 8.8125 20.85C8.57083 20.75 8.35833 20.6083 8.175 20.425L4.075 16.325C3.89167 16.1417 3.75 15.9292 3.65 15.6875C3.55 15.4458 3.5 15.1917 3.5 14.925V9.075C3.5 8.80833 3.55 8.55417 3.65 8.3125C3.75 8.07083 3.89167 7.85833 4.075 7.675L8.175 3.575C8.35833 3.39167 8.57083 3.25 8.8125 3.15C9.05417 3.05 9.30833 3 9.575 3H15.425C15.6917 3 15.9458 3.05 16.1875 3.15C16.4292 3.25 16.6417 3.39167 16.825 3.575L20.925 7.675C21.1083 7.85833 21.25 8.07083 21.35 8.3125C21.45 8.55417 21.5 8.80833 21.5 9.075V14.925C21.5 15.1917 21.45 15.4458 21.35 15.6875C21.25 15.9292 21.1083 16.1417 20.925 16.325L16.825 20.425C16.6417 20.6083 16.4292 20.75 16.1875 20.85C15.9458 20.95 15.6917 21 15.425 21H9.575Z"
          fill="#FF5353"
        />
      </g>
    </svg>
  )
}

export default Error
