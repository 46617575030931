import { FormResponse } from "components/ContactForm/FormWrapper"
import * as s from "components/EmbeddedForm/ContactForm.css"
import Checkbox from "components/shared/Checkbox"
import Error from "components/shared/icons/Error"
import Success from "components/shared/icons/Success"
import { Button, ButtonProps } from "components/shared/panda/Button"
import { getPath, getUtmFields } from "lib/helpers"
import Link from "next/link"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { ComponentPropsWithoutRef, FormEventHandler, useState } from "react"
import { css, cx } from "styled-system/css"
import { styled, VStack, Wrap } from "styled-system/jsx"
import { hstack } from "styled-system/patterns"

type Props = {
  submitLabel: string
  inputProps?: ComponentPropsWithoutRef<"input">
  buttonProps?: Partial<ButtonProps>
  formPage: string
}

const SmallForm = ({
  submitLabel,
  inputProps,
  buttonProps,
  formPage,
}: Props) => {
  const [data, setData] = useState<FormResponse>()

  const { t } = useTranslation()
  const { locale, push } = useRouter()

  const translate = key => t(`validation.${key}`, { defaultValue: key || "" })

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()

    setData(null)

    const data = {
      email: event.target.email.value,
      marketing: event.target.marketing.checked,
      tags: ["inbound"],
      simple: true,
      custom_fields: [
        { field: "form_id", value: "simple-form" },
        ...getUtmFields(),
      ],
    }

    const endpoint = "/api/create-lead"

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }

    const response = await fetch(endpoint, options)

    const result = await response.json()

    setData(result)

    if (!result.error) {
      event.target.reset()

      push({
        pathname: `/${formPage}`,
        query: { email: data.email },
      })
    }
  }

  return (
    <VStack
      id="simple-form"
      as="form"
      gap={6}
      alignItems={{ lg: "start" }}
      method="POST"
      action="/api/create-lead"
      // @ts-expect-error
      onSubmit={handleSubmit}
      css={{
        textAlign: "start",
        rowGap: "12px!",
      }}
      data-type="smallForm">
      {data &&
        (data.error ? (
          <div className={s.bad}>
            {/* <styled.p className={hstack({ gap: 2 })}>
              <Error />
              {translate(data.message)}
            </styled.p> */}
            <VStack gap={2} alignItems="start">
              {data?.message && <li>{data?.message}</li>}
              {data?.data?.errors &&
                Object.entries(data.data.errors).map(([key, value]) => (
                  <li key={key}>
                    <>
                      [{t(key)}] {translate(value[0])}
                    </>
                  </li>
                ))}
              {data.issues?.map((x, i) => (
                <styled.p key={i} className={hstack({ gap: 2 })}>
                  <styled.span flex="1 0">
                    <Error />
                  </styled.span>{" "}
                  {translate(x.message)}
                </styled.p>
              ))}
            </VStack>
          </div>
        ) : (
          <div className={cx(s.good, hstack({ gap: 2 }))}>
            <Success />
            {translate(data.message)}
          </div>
        ))}
      <Wrap gap={2}>
        <styled.input
          name="email"
          type="text"
          placeholder="Email"
          css={{
            border: "1px solid #9FAFFF",
            color: "primary.1",
            flex: 1,
            fontSize: { base: "10px", lg: "18px" },
            rounded: { base: "6px", lg: "16px" },
            p: { base: "8px 24px", lg: "16px 40px" },
            minW: "min(100%, 300px)",
            maxW: "100%",
          }}
          {...inputProps}
        />
        <Button
          css={{ flex: 1, minW: "min(100%, 200px)", maxW: "100%" }}
          responsive
          {...buttonProps}>
          {submitLabel}
        </Button>
      </Wrap>

      <Checkbox
        name="marketing"
        label={
          <>
            Si, acepto la{" "}
            <Link href={getPath("privacyPolicy", locale)}>
              política de privacidad
            </Link>
            , y recibir comunicaciones sobre los productos, contenido y eventos
            de Vixiees.*
          </>
        }
        // TODO: translation
        // label={
        //   <Trans
        //     i18nKey="marketingLabel"
        //     components={{
        //       // terms: <Link href={getPath("termsConditions", locale)} />,
        //       privacy: <Link href={getPath("privacyPolicy", locale)} />,
        //     }}
        //   />
        // }
        className={css({
          "& label": {
            // color: "white",
            fontSize: "12px",

            lg: {
              fontSize: "16px",
            },
          },
        })}
      />
    </VStack>
  )
}

export default SmallForm
export type { Props as SmallFormProps }
