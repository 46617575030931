import { ComponentPropsWithoutRef } from "react"
import { cva, RecipeVariantProps } from "styled-system/css"
import { styled } from "styled-system/jsx"
import { SystemStyleObject } from "styled-system/types"

const titleStyle = cva({
  base: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    mb: "30px",
    padding: "8px 24px",
    rounded: "90px",
    border: "2px solid",
    borderColor: "white",
    fontFamily: "heading",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
  variants: {
    responsive: {
      true: {
        lgDown: {
          mb: "12px",
          padding: "4px 12px",
          fontSize: "12px",
        },
      },
    },
  },
  defaultVariants: {},
})

export type RoundedTitleVariants = RecipeVariantProps<typeof titleStyle>
export const RoundedTitle = styled("h4", titleStyle)

export type RoundedTitleProps = {
  children
  css: SystemStyleObject
} & RoundedTitleVariants &
  ComponentPropsWithoutRef<"h4">
