import { PortableText } from "@portabletext/react"
import Title, { TitleProps } from "components/shared/Title"
import { ReactNode } from "react"

type Props = TitleProps & {
  children: ReactNode
}

const PortableTitle = ({ children, ...rest }: Props) => {
  return (
    <Title {...rest}>
      <PortableText
        // serializers={{
        //   normal: ({ children }: any) => {
        //     return <>{children}</>
        //   },
        // }}
        components={{
          marks: {
            em: ({ children, text }) => <em data-text={text}>{children}</em>,
            // em2: ({ children }) => <em>{children}</em>,
          },
        }}
        value={children}
      />
    </Title>
  )
}

export type { Props as TitleProps }
export default PortableTitle
