import { InputHTMLAttributes } from "react"
import { cx } from "styled-system/css"
import { HStack, styled } from "styled-system/jsx"
import { square } from "styled-system/patterns"

type Props = {
  className?: string
  inputClassName?: string
  label?: string | Element
} & InputHTMLAttributes<HTMLInputElement>

function Checkbox({ className, inputClassName, label, name, ...rest }: Props) {
  return (
    <HStack
      className={className}
      css={{
        "& a": {
          textDecoration: "underline",
          transition: "opacity 0.3s",

          "&:hover": {
            opacity: 0.7,
          },
        },
      }}>
      <styled.input
        name={name}
        type="checkbox"
        className={cx(
          square({
            size: 4,
            rounded: "4px",
            border: "1px solid #000",
          }),
          inputClassName
        )}
        {...rest}
      />
      <styled.label
        htmlFor={name}
        css={{
          fontSize: "14px",
          lineHeight: "133%",
        }}>
        {label || "x"}
      </styled.label>
    </HStack>
  )
}

export default Checkbox
export type { Props as CheckboxProps }
